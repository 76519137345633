import React from "react";
import StaticImage from "@components/StaticImage";

import "./Clients.styles.scss";

const Clients = () => {
  return (
    <section className="clients" id="clients">
      <div className="clients__inner container">
        <StaticImage filename="clients1.png" />
        <StaticImage filename="clients2.png" />
      </div>
    </section>
  );
};

export default Clients;
