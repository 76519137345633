import React from "react";

import Header from "@layouts/Header";
import Clients from "@sections/Clients";
import Info from "@sections/Info";
import InfoGrid from "@sections/InfoGrid";
import InfoVertical from "@sections/InfoVertical";

import ContactForm from "@containers/ContactForm";

import "./Home.styles.scss";

const Home = ({ data }) => {
  const {
    header = {},
    quality = {},
    where = {},
    analyse = {},
    learn = {},
    score = {},
    contact = {},
    navbar ={}
  } = data;

  return (
    <div className="home">
      <Header {...header} />
      <div className="home__message">
        <span>You're in good company</span>
      </div>
      <Clients />
      <Info {...quality} />
      <InfoGrid {...where} />
      <InfoVertical {...analyse} />
      <InfoVertical {...learn} />
      <Info {...score} />
      <ContactForm {...contact} />
    </div>
  );
};

export default Home;
