import React from "react";
import classNames from "classnames";
import Image from "@components/Image";

import "./InfoVertical.styles.scss";

const InfoVertical = ({
  title,
  description,
  images = [],
  children,
}) => {
  return (
    <section className="info-vertical">
      <div className="info-vertical__inner container">
        <div className="info-vertical__content">
          <h2 className="title title-2">{title}</h2>
          <p className="description description-2">{description}</p>
        </div>
        <div
          className={classNames("info-vertical__images", {
            "info-vertical__images--big": images.length === 1,
            "info-vertical__images--fluid": !images.length,
          })}
        >
          {images?.length
            ? images.map((item, key) => {
                return <Image data={item?.image} key={key} />;
              })
            : children}
        </div>
      </div>
    </section>
  );
};

export default InfoVertical;
