import React from "react";
import Image from "@components/Image";

import "./InfoGrid.styles.scss";

const InfoGrid = ({ title, description, images }) => {
  return (
    <section className="info-grid info-grid--reverse" id="info-grid">
      <div className="info-grid__inner container">
        <div className="info-grid__content">
          <h2 className="title title-2">{title}</h2>
          <p className="description description-2">{description}</p>
        </div>
        <div className="info-grid__grid">
          <div className="info-grid__grid__wrapper">
            {images?.map(image => {
              return <Image data={image?.image} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoGrid;
