import React from "react";
import HomeTemplate from "./Home";

import Layout from "@layouts/Layout";

const IndexPage = ({ data: initialData }) => {
  const cmsData = initialData?.markdownRemark?.frontmatter;
  const seo = {
    ...(cmsData?.seo || {}),
    image: cmsData?.seo?.image?.childImageSharp?.fluid?.src,
  };

  const data = {
    ...cmsData,
  };

  const navbar = initialData?.navbar?.frontmatter;

  return (
    <Layout navbar={navbar} seo={seo}>
      <HomeTemplate data={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexPageTemplate {
    navbar: markdownRemark(
      frontmatter: { templateKey: { eq: "NavbarComponent" } }
    ) {
      frontmatter {
        phone
        Items {
          name
          link
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "Home" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          description
          buttonText
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        quality {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        where {
          title
          description
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        analyse {
          title
          description
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        learn {
          title
          description
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        score {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contact {
          title
        }
      }
    }
  }
`;

export default IndexPage;
