import React from "react";
import classNames from "classnames";
import Image from "@components/Image";

import "./Info.styles.scss";

const Info = ({
  title = "",
  description = "",
  image = "",
  isReverse,
}) => {
  return (
    <section
      className={classNames("info", { "info--reverse": isReverse })}
      id="info"
    >
      <div className="info__inner container">
        <div className="info__content">
          <h2 className="title title-2">{title}</h2>
          <p className="description description-2">{description}</p>
        </div>
        <div className="info__image">
          <div className="info__image__wrapper">
            <Image data={image} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
